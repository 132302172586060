import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e4997804"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dsk-content" }
const _hoisted_2 = {
  class: "wrapper",
  style: {"width":"100%","height":"100%"}
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "grid-content bg-purple",
  style: {"width":"100%"}
}
const _hoisted_6 = { class: "dsk-info" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplideSlide = _resolveComponent("SplideSlide")!
  const _component_Splide = _resolveComponent("Splide")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.list.title), 1),
    _createVNode(_component_el_row, { style: {"width":"100%"} }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24,
          xl: 24
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Splide, {
                "aria-labelledby": "thumbnail-example-heading",
                options: _ctx.mainOptions,
                ref: "main"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.imgfile, (slide) => {
                    return (_openBlock(), _createBlock(_component_SplideSlide, {
                      key: slide.files_id
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          style: {"width":"100%"},
                          fit: "cover",
                          src: slide.url
                        }, null, 8, _hoisted_3)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["options"]),
              _createVNode(_component_Splide, {
                "aria-label": "The carousel with thumbnails. Selecting a thumbnail will change the main carousel",
                options: _ctx.thumbsOptions,
                ref: "thumbs"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.imgfile, (slide) => {
                    return (_openBlock(), _createBlock(_component_SplideSlide, {
                      key: slide.files_id
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: slide.url
                        }, null, 8, _hoisted_4)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["options"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24,
          xl: 24
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("article", {
                  style: {"width":"100%"},
                  innerHTML: _ctx.list.content
                }, null, 8, _hoisted_7)
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}