import axiosApi from '@/utils/request'

// 通过token获取用户信息
export function getUserInfo(headers = {}, params = {}) {
    return axiosApi('/visual/user/get-user-info','post',params,headers,'',2)
}

// 退出登录
export function loginOut(headers = {}, params = {}) {
    return axiosApi('/visual/user/login-out','post',params,headers,'',2)
}
