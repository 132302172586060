<template>
<!-- 领导致辞 -->
<div class="box" :style="note">
    <div style="padding-top:8vh;"><center><h1>热烈欢迎领导致辞</h1></center></div>
</div>

</template>
<script>
import { getModulesBg } from '@/api/modules'
export default {
  components: {

  },
  data () {
    return {
      headers: {
        'token': sessionStorage.getItem('token'),
        'authkey': this.$authkey,
        'Content-Type': 'application/json'
      },
      note: {
        backgroundImage: '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        width: '100%',
        height: '100%'
      },
    }
  },
  created () {
    this.getModulesBg()
  },
  methods: {
    getModulesBg(){
        getModulesBg(this.headers,{'path':this.$route.path}).then((res) => {
          this.note.backgroundImage =  'url(' + res.data.background + ')'
        })
    }
  }
}
</script>
<style scoped>
    h1{

        color: yellow;
        letter-spacing:1em;
        font-size: 3em;
    }
</style>

