<template>
  <!-- <div class="dsk-content"> -->
   <iframe id="bankUrl" :src="list.bank_url" allowfullscreen></iframe>
  <!-- </div> -->
</template>
<script>
export default {
  components: {

  },
  data () {
    return {
      list:[]
    }
  },
  methods: {
    getData(list) {
      this.list = list
    },
  },
};
</script>
<style scoped>
#bankUrl {
  width: 98%; height: 72vh; border: 0px;
}
</style>