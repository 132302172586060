<template>
<!-- 桌面 -->
<div class="box" :style="note">
  <center><br><br><h1>{{name}}</h1></center>
  <el-row :gutter="30" id = "dash" >
    <el-col :span="span" v-for="(info, i) in lineData" :key="i">
      <el-card class="dashCard" shadow="always" @click="go(info.path)"> 
        <center>
          <img class="app" :src="info.thumbnail"/>
          <span>{{info.name}}</span>
        </center>
      </el-card>
    </el-col>
  </el-row>
</div>
</template>
<script>
import { getModulesList } from '@/api/modules'
import { specialBrowsing } from '@/api/special'
export default {
  components: {

  },
  data () {
    return {
      lineData:[],
      name:'',
      headers: {
        'token': sessionStorage.getItem('token'),
        'authkey': this.$authkey,
        'Content-Type': 'application/json'
      },
      span: 8,
      note: {
        backgroundImage:'',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        width: '100%',
        height: '100%'
      },
      timer: null
    }
  },
  created () {
    this.getModulesList()
  },
  methods: {
    // 跳转功能模块
    go(url){
      this.$router.push({
          path: url
      })
    },
    popUp(){

    },
    // 获取模块列表
    getModulesList(){
        specialBrowsing(this.headers, {'type': 0, 'special_id': 0})
        getModulesList(this.headers,{}).then((res) => {
          this.name = res.data.top.name
          this.note.backgroundImage =  'url(' + res.data.top.background + ')'
          if (res.data.count > 6) {
            this.span = 6
          }
          setTimeout(() => {this.lineData = res.data.data}, 100)
        })
    }
  }
}
</script>
<style scoped>
  .box h1{
    color:#fcfcfd;
  }
  #dash{
    width: 65%;
    position: absolute;
    top: 22%;
    right: 17.5%;
  }
  .dashCard{
    margin-bottom: 10%;
    cursor:pointer;
    color: #fcfcfd;
    border-radius: 10px;
    background: #f2f2f224;
    border: 0px;
  }
  .app{
    border-radius: 10px;
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 10%);
    width: 100%;
    margin-bottom: 10px;
    height: 20vh;
    object-fit:cover;
  }
  .lineCard{
    margin-bottom: 10%;
    cursor:pointer;
    border-radius: 10px;
    background: #f2f2f224;
    border: 0px;
  }
</style>

