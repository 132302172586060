import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import ElementPlus from 'element-plus'
import { ElMessage } from 'element-plus'
import vue3SeamlessScroll from "vue3-seamless-scroll";
import VueAutoScroll from '@fcli/vue-auto-scroll';

// import 'element-plus/dist/index.css'
require("element-plus/dist/index.css");
import router from './router'
const app = createApp(App);
// app.config.globalProperties.$visual = "localhost:8080" // 本地时间轴地址
// app.config.globalProperties.$visual = "local.hy.edu-xr.com" // 本地服务器
// app.config.globalProperties.$visual = "t.edu-xr.com" // 测试
app.config.globalProperties.$visual = "hy.edu-xr.com" // 演示
// app.config.globalProperties.$visual = "cloud.edu-xr.com" // 正式
app.use(vue3SeamlessScroll)
app.use(store)
app.use(ElementPlus)
app.use(VueAutoScroll);
app.config.globalProperties.$ElMessage = ElMessage
app.config.globalProperties.$authkey = '0a2ee68f79089ab75f8a7f1983bcb914'
app.use(router)
app.mount('#app')
