<template>
    <!-- VR导览 -->
    <div class="box">
        <iframe allowfullscreen class="box" :src="bank_url" frameborder="0"></iframe>
    </div>
    
    </template>
    <script>
    export default {
      components: {
    
      },
      data () {
        return {
          headers: {
            'token': sessionStorage.getItem('token'),
            'authkey': this.$authkey,
            'Content-Type': 'application/json'
          },
          bank_url: '',
        }
      },
      created () {
        this.bank_url = this.$route.query.bank_url
      },
      methods: {

      }
    }
    </script>
    <style scoped>
      .box{
        background: linear-gradient(135deg,#cebe29 0,#9b1f50 33%,#2989d8 71%,#89b4ff 91%);
        width: 100%;
        height: 100%;
        z-index: 19000;
      }
    </style>
    
    
  
  