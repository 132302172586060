<template>
<!-- XR资源详情 -->
<div class="box" style="background-color: black;">
    <ShowVideo v-if="data.cate_ids == 2 || data.cate_ids == 3" :files="data.files"></ShowVideo>
    <ShowPicture  v-if="data.cate_ids == 4 " :files="data.files"></ShowPicture>
</div>
</template>
<script>
import { getResOne } from '@/api/xrres'
import ShowPicture from './ShowPicture.vue'
import ShowVideo from './ShowVideo.vue'
export default {
  components: {
    ShowPicture,
    ShowVideo
  },
  created () {
    this.findOne()
  },
  data () {
    return {
      headers: {
        'token': sessionStorage.getItem('token'),
        'authkey': this.$authkey,
        'Content-Type': 'application/json'
      },
      data: []
    }
  },
  methods: {
    // 获取XR单个资源
    findOne(){
      let resID = this.$route.query.resID
      getResOne(this.headers,{'res_id': resID}).then((res) => {
        this.data = res.data
      })
    }
  }
}
</script>
<style scoped>
</style>

