<template>
    <!-- 照片墙 -->
    <div class="box">
        <el-card class="paper" shadow="always">
              <!-- 照片墙 -->
              <Vue3Marquee :pauseOnHover="true" :duration="130">
                <div class="card" v-for="avatar in imgArr1" :key="avatar">
                  <img :src="avatar" style="widht:auto; height:25vh; margin-left:20px;" @click="showBigImg(avatar)"  />         
                </div>
              </Vue3Marquee>
              <br>
              <Vue3Marquee :pauseOnHover="true" :duration="260" direction="reverse">
                <div class="card" v-for="avatar in imgArr2" :key="avatar">
                  <img :src="avatar" style="widht:auto; height:25vh; margin-left:20px;" @click="showBigImg(avatar)"  />            
                </div>
              </Vue3Marquee>
                  <br>
              <Vue3Marquee :pauseOnHover="true" :duration="130">
                <div class="card" v-for="avatar in imgArr3" :key="avatar">
                  <img :src="avatar" style="widht:auto; height:25vh; margin-left:20px;" @click="showBigImg(avatar)"  />             
                </div>
              </Vue3Marquee>
        </el-card>
        <el-image-viewer v-if="imgViewerVisible" @close="closeImgViewer" :url-list="[currentImgViewerUrl]" :hide-on-click-modal="true"></el-image-viewer>
    </div>
    
    </template>
    <script>
    import { getPhotoList } from '@/api/photoWall'
    import { Vue3Marquee } from 'vue3-marquee'
    import 'vue3-marquee/dist/style.css'
    export default {
      components: {
        Vue3Marquee
      },
      data () {
        return {
          headers: {
            'token': sessionStorage.getItem('token'),
            'authkey': this.$authkey,
            'Content-Type': 'application/json'
          },
          imgArr1: [],
          imgArr2: [],
          imgArr3: [],
          imgViewerVisible: false,
          currentImgViewerUrl:''
        }
      },
      created () {
        var pid = sessionStorage.getItem('pid')
        this.getPhotoList(pid);
      },
      methods: {
        getPhotoList(pid){
          getPhotoList(this.headers,{'special_id':pid}).then((res) => {
              this.imgArr1 = res.data.imgArr1
              this.imgArr2 = res.data.imgArr2
              this.imgArr3 = res.data.imgArr3
            })
        },
        showBigImg(src){
          this.imgViewerVisible = true
          this.currentImgViewerUrl= src
        },
        closeImgViewer(){
          this.imgViewerVisible = false
          this.currentImgViewerUrl = ''
        }
      },
    }
    </script>
    <style scoped>
    .box{
      padding-top:8vh;
      padding-left:1vh;
      padding-right:1vh;
    }
    .paper {
        cursor:pointer;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.5);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        border: 0px;
    }
    /deep/.el-image-viewer__actions {
        width: 40vw;
        height: 8vh;
    }
    /deep/.el-image-viewer__actions__inner {
        font-size: 7vh;
    }
    </style>
    
    