<template>
<!-- 数据可视化中心 -->
<div class="box">
  <el-row :gutter="30" id= "content">
    <el-col :span="getSpan(i)" v-for="(info, i) in listData" :key="i">
      <div class="paper" shadow="always">
        <NewsContent :newsInfo="info" @bigImage="showBigImg" ref="newsContent"  />
      </div>
    </el-col>
  </el-row>
  <el-image-viewer v-if="imgViewerVisible" @close="closeImgViewer" :url-list="[currentImgViewerUrl]" :hide-on-click-modal="true"></el-image-viewer>
</div>
</template>
<script>
import NewsContent from './news/news'
export default {
  components: {
    NewsContent
  },
  data () {
    return {
      headers: {
        'token': sessionStorage.getItem('token'),
        'authkey': this.$authkey,
        'Content-Type': 'application/json'
      },
      scroll: true,
      imgViewerVisible: false,
      currentImgViewerUrl:'',
      pageCount: 0,
      pageSize: 6,
      count: 1,
      specialId: '',
      page: 1, 
      listData: [],
    }
  },
  created () {

  },
  methods: {
    showBigImg(url){
      this.imgViewerVisible = true
      this.currentImgViewerUrl= url
    },

    closeImgViewer(){
      this.imgViewerVisible = false
      this.currentImgViewerUrl = ''
    },
    
    getData(newdata) {
      setTimeout(() => {
        this.listData = newdata
      }, 0)
    },
    // 获取比例
    getSpan(i){
      return 4 
    },
    isInteger(obj) {
      return obj%1 === 0
    },
  }
}
</script>
<style scoped>
  #content{
    width: 100%;
    height: 100%;
  }
  .paper{
    /* margin-bottom: 10%; */
    height: 68vh;
    cursor:pointer;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border: 0px;
    color: #eaf6f9;
    font-size: 18px;
    letter-spacing: 0.2em;
    line-height: 1.5em;
    text-align: justify;
    padding: 15px;
  }
  /deep/.paper img{
    width: 80%;
    margin: 15px 10%;
    height: auto;
  }
  .overview{
    background: #00000042;
    padding: 15px;
    margin-bottom: 10px;
  }
  .dsknav{
    position: absolute;
    bottom: -20px;
  }
  .sizepage{
    position: absolute;
    bottom: -20px;
  }
  /deep/.el-image-viewer__actions {
    width: 40vw;
    height: 8vh;
  }
  /deep/.el-image-viewer__actions__inner {
    font-size: 7vh;
  }
</style>

