<template>
  <div class="dsk-content">
    <h2>{{ list.title }}</h2>
    <br>
    <el-row style=" width:100%;">
      <el-col :span="24">
        <div  @click="onDialog()" >
          <div style="position: absolute;bottom: 45%;width:100%;"><center><img src="@/assets/image/icon_operation_playtime.png"></center></div>
          <img class="app" :src="list.thumbnail" />
        </div>
      </el-col>
      <el-col :span="24">
          <div class="grid-content bg-purple" style="width:100%;">
            <div class="dsk-info">
                  <br>
                 <article style="width:100%;" v-html="list.content"></article>
            </div>
          </div>
      </el-col>
    </el-row>
    <el-dialog
        v-model="dialogVisible"
        :before-close="closeDialog"
        :destroy-on-close="true"
        width="100%"
        :isDisabled="false"
        top="5%"
        center>
    <video controlsList="nodownload" v-if="list.isvr == 1" style="width: 100%;  border: 0px;" controls="controls" class="vjs-tech" playsinline="playsinline" webkit-playsinline="true" x5-playsinline="true" x5-video-player-type="h5" x5-video-player-fullscreen="false" tabindex="-1" preload="auto" :src="list.video.url"></video>
    <iframe v-if="list.isvr == 2"  style="width: 100%; height:40vh;  border: 0px;" :src="getUrl(list.video.url)" allowfullscreen></iframe>
  </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    newsInfo: {
      type: Array
    }
  },
  name: 'NewsVideo',
  components: {

  },
  data () {
    return {
      dialogVisible: false,
      list: this.newsInfo
    }
  },
  methods: {
     closeDialog() {
      this.dialogVisible = false
    },
    onDialog() {
      this.dialogVisible = true
    },
    getUrl(url) {
      return axios.defaults.baseURL+'/pano-video/play?token='+sessionStorage.getItem('token')+'&url='+url
    },
  },
};
</script>
<style scoped>
#thumbnail .splide__slide {
  opacity: 0.6;
}
#thumbnail .splide__slide.is-active {
  opacity: 1;
}
.dsk-inf::-webkit-scrollbar {
    width: 8px;
}
.dsk-inf:hover ::-webkit-scrollbar-track-piece {
    /* 滚动条的背景颜色 */
    border-radius: 6px;
}
.dsk-inf:hover::-webkit-scrollbar-thumb:vertical {
    border-radius: 6px;
    outline: 2px solid #fff;
    outline-offset: -2px;
}
.app{
  border-radius: 10px;
  box-shadow: 0 2px 16px 0 rgb(0 0 0 / 10%);
  width: 80%;
  margin-left:10%;
  margin-bottom: 10px;
  height: 14em;
  object-fit:cover;
}
/deep/ .el-dialog__header {
    margin-right: 0px;
    background-color: #222;
}
/deep/ .el-dialog__body {
    background-color: #222;
}
</style>