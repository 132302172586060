import axiosApi from '@/utils/request'

// 获取资源列表
export function resManage(headers = {}, params = {}) {
    return axiosApi('/visual/res-manage/index', 'post', params, headers, '', 2)
}

// 获取单个资源及文件
export function getResOne(headers = {}, params = {}) {
    return axiosApi('/visual/res-manage/get-res-one', 'post', params, headers, '', 2)
}