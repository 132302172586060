<template>
    <div id="logo">
      <img :src="logo" alt="" v-if="logo">
      <!-- <img src="//edu-xr-bj.oss-cn-beijing.aliyuncs.com/upload/76/2022-03-275c3c5117f60f143351403daffd8274e7.png" alt=""> -->
    </div>
  <!-- <el-dropdown>
    <span class="el-dropdown-link">
     {{userInfo.nickname}}
    </span>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item @click="goAdmin">管理后台</el-dropdown-item>
        <el-dropdown-item @click="close">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown> -->
  <MQTT ref="mqtt" />
</template>
<script>
import { getUserInfo, loginOut } from '@/api/user'
import MQTT from '../components/asset/MqttView.vue'
import { getPlan } from '@/api/plan'
export default {
  components: {
    MQTT
  },
  props: {

  },
  data () {
    return {
      userInfo:'',
      logo: '',
      headers: {
        'token': '',
        'authkey': this.$authkey,
        'Content-Type': 'application/json'
      }
    }
  },
  created () {
    var token = this.ReadCookie('token')
    sessionStorage.setItem('token', token)
    this.headers.token = token
    getUserInfo(this.headers).then((res) => {
      this.$refs.mqtt.connect(res.data.admin_id, res.data.sid, res.data.user_id, res.data.timeLine) 
      this.userInfo = res.data
      if (res.data.time_logo) {
        this.logo = res.data.time_logo
      }
    })
    this.clearTimer()
    this.timer = setInterval(this.getPlan, 30000)
  },
  methods: {
    getPlan(){
        getPlan(this.headers)
    },
    // 回到管理页面
    goAdmin () {
        sessionStorage.clear()
        this.delCookie('token')
        this.$router.go(this.$visual)
    },
    // 退出登录
    close () {
      loginOut(this.headers).then(() => {
        this.$ElMessage({
          message: '退出成功',
          type: 'success',
        })
        this.delCookie('token')
        sessionStorage.clear()
        this.$router.go(this.$visual)
      })
    },
    delCookie(name){
        var exp = new Date();
        exp.setTime(exp.getTime() - 1);
        var cval=this.ReadCookie(name);
        if(cval!=null){
            document.cookie= name + "="+cval+";expires="+exp.toGMTString();
        } 
    },
    // 获取cookie值
    ReadCookie(cookieName) {
      var theCookie = ""+document.cookie
      var ind = theCookie.indexOf(cookieName);
      if (ind ==-1 || cookieName=="") {
        return ""
      }
      var ind1 = theCookie.indexOf(';',ind);
      if (ind1==-1) {
        ind1 = theCookie.length
      }
      return unescape(theCookie.substring(ind+cookieName.length+1,ind1))
    },
    // 清除定时器
    clearTimer () {
      clearInterval(this.timer)
      this.timer = null
    },
  }
}
</script>
<style scoped>
 .el-dropdown-link {
  color: #fcfcfd;
}

#logo{
  position: absolute;
  top: 8px; 
  left: 15px;
}
#logo img{
  width: auto; 
  height: 6vh;
  object-fit: scale-down;
}
</style>
