<template>
  <!-- <div class="dsk-content"> -->
    <el-row style=" width:100%; height:100%" :gutter="20">
      <el-col :span="10">
          <div class="grid-content bg-purple" style="width:100%; height:100%">
            <div class="dsk-info">
                <h2>{{ list.title }}</h2>
                  <br>
                 <article style="width:100%;" v-html="list.content"></article>
            </div>
          </div>
      </el-col>
      <el-col :span="14">
         <div class="dsk-info">
            <video controlsList="nodownload" v-if="list.isvr == 1" style="width: 100%; height: 60vh; border: 0px;" controls="controls" class="vjs-tech" playsinline="playsinline" webkit-playsinline="true" x5-playsinline="true" x5-video-player-type="h5" x5-video-player-fullscreen="false" tabindex="-1" preload="auto" :src="list.video.url"></video>
            <iframe v-if="list.isvr == 2"  style="width: 100%; height: 60vh; border: 0px;" :src="list.video.url" allowfullscreen></iframe>
         </div>
      </el-col>
    </el-row>
  <!-- </div> -->
</template>
<script>
import axios from "axios";
export default {
  name: 'NewsVideo',
  components: {

  },
  data () {
    return {
      list:[]
    }
  },
  methods: {
    getData(list) {
      this.list = list
      if (list.isvr == 2) {
        this.list.video.url = axios.defaults.baseURL+'/pano-video/play?token='+sessionStorage.getItem('token')+'&url='+list.video.url
      }
    },
  },
};
</script>
<style scoped>
.splide__slide {
  margin-top:5px;
}
#thumbnail .splide__slide {
  opacity: 0.6;
}
#thumbnail .splide__slide.is-active {
  opacity: 1;
}
.splide--dynamic .splide__list {
  min-height: 15rem;
}
.dsk-info{
    color: #fcfcfd;
    overflow-y: scroll;
    padding: 10px;
    margin-right: 10px;
}
.dsk-inf::-webkit-scrollbar {
    width: 8px;
}
.dsk-inf:hover ::-webkit-scrollbar-track-piece {
    /* 滚动条的背景颜色 */
    border-radius: 6px;
}
.dsk-inf:hover::-webkit-scrollbar-thumb:vertical {
    border-radius: 6px;
    outline: 2px solid #fff;
    outline-offset: -2px;
}
.dsk-info{
    height:73vh;
    padding: 5vh;
    cursor: pointer;
    overflow-y: scroll;
    border-radius: 2vh;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border: 0px;
    line-height:4vh;
    letter-spacing:.2vh;
    color: #eaf6f9;
}
.dsk-info::-webkit-scrollbar {
    width: 8px;
}
.dsk-info:hover ::-webkit-scrollbar-track-piece {
    /* 滚动条的背景颜色 */
    border-radius: 6px;
}
.dsk-info:hover::-webkit-scrollbar-thumb:vertical {
    border-radius: 6px;
    outline: 2px solid #fff;
    outline-offset: -2px;
}
</style>