<template>
<!-- 桌面 -->
<div class="box">
  <!-- <center><br><br><h1> XR 资 源 </h1></center> -->
  <el-row :gutter="30" id = "dash" >
    <el-col :span="6" v-for="(info, i) in lineData" :key="i">
      <el-card class="dashCard" shadow="always" @click="goRes(info.res_id)"> 
        <center>
          <img class="app" :src="info.cover"/>
          <!-- 时间轴 -->
          <span>{{info.title}}</span>
        </center>
      </el-card>
    </el-col>
  </el-row>
  <div class="dsk-nav">
    <span class="dsk-pre" @click="last()"></span>
    <span class="dsk-next" @click="next()"></span>
  </div>
  <div class="pageSize">
    <b>
    <center>第 <span>{{ page }}</span> 页 &nbsp; 共 <span>{{ pageCount }}</span> 页 &nbsp;</center>
    </b>
  </div>
</div>
</template>
<script>
import { resManage } from '@/api/xrres'
export default {
  components: {

  },
  created () {
    if (this.$route.query.page){
      this.page = this.$route.query.page
    }
    if (this.$route.query.pid){
      this.pid = this.$route.query.pid
    }else{
      this.pid = sessionStorage.getItem('pid')
    }
    this.getResList()
  },
  data () {
    return {
      headers: {
        'token': sessionStorage.getItem('token'),
        'authkey': this.$authkey,
        'Content-Type': 'application/json'
      },
      pid: '',
      pageSize: 8,
      page: 1,
      pageCount: 0,
      lineData:[]
    }
  },
  methods: {
    // 资源浏览
    goRes(resID){
      let url = '/xrres?page='+this.page+'&pid='+this.pid
      sessionStorage.setItem('goBakUrl', url)
      let params = {
        resID: resID
      }
      this.$router.push({
          path: '/xrres-one', 
          query: params
      })
    },
    getResList(){
        resManage(this.headers,{'pageSize': this.pageSize, 'page': this.page, pid: this.pid}).then((res) => {
            this.lineData = res.data
            this.pageCount = res.pageCount
        })
    },
    last(){
      this.page--
      if (this.page <= 0) {
        this.page++ 
      }
      this.getResList()
    },
    next(){
      this.page++
      if (this.page > this.pageCount) {
        this.page--
      }
      this.getResList()
    }
  }
}
</script>
<style scoped>
  .box{
      width: 100%;
      height: 100%;
  }
  .box h1{
    color:#fcfcfd;
  }
  #dash{
    width: 60%;
    position: absolute;
    top: 22%;
    right: 20%;
  }
  .dashCard{
    margin-bottom: 10%;
    cursor:pointer;
    color: #fcfcfd;
    /* overflow-y: scroll; */
    border-radius: 10px;
    background: #f2f2f224;
    border: 0px;
  }
  .app{
    border-radius: 10px;
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 10%);
    width: 100%;
    margin-bottom: 10px;
    height: 20vh;
    object-fit:cover;
  }
  .lineCard{
    margin-bottom: 10%;
    cursor:pointer;
    /* overflow-y: scroll; */
    border-radius: 10px;
    background: #f2f2f224;
    border: 0px;
  }
</style>

