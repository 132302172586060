<template>
  <transition name="el-zoom-in-left">
      <router-view/>
  </transition>
    <div style="position: absolute; bottom:1%; right: 10px;">
      <el-button style="width: 5vh;height: 5vh;" :icon="Back" circle size="large" @click="goBak()" />
    </div>
    <UserView/>
    <!-- <div id="logo">
      <img src="@/assets/image/toplogo.png" alt="">
    </div> -->
    <div id="footer" style="
          position: absolute;
          left: -3vh;
          bottom: 5px;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.25);">
     <b>
      <center style="-webkit-transform: scale(0.8)">
        © Copyright © 2017-{{year}} 北京幻育科技有限公司 
        <br>
      </center>
     </b>
    </div>
</template>
<script setup>
  import { Back } from '@element-plus/icons-vue'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import {onBeforeUnmount, onBeforeMount} from "vue";

  onBeforeMount(() => {
    // 检查本地存储中是否已经执行过刷新操作
    const hasRefreshed = sessionStorage.getItem('hasRefreshed');
    // window.console.log('检查本地存储中是否已经执行过刷新操作'+hasRefreshed)
    // 如果本地存储中没有记录或者记录为false，则执行刷新操作
    if (!hasRefreshed) {
      // window.console.log('执行刷新操作')
      // 5秒后执行刷新操作
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      // 设置本地存储，标记已经执行过刷新操作
      sessionStorage.setItem('hasRefreshed', true);
    }
  })

  // 在组件销毁时清除存储，下次进入才会触发
  onBeforeUnmount(() => {
    // window.console.log('在组件销毁时清除存储，下次进入才会触发')
    sessionStorage.removeItem('hasRefreshed');
  });

</script>
<script>
import UserView from './views/UserView.vue'
import { shutdownNow } from '@/api/goPower'
export default {
  components: { 
    UserView
  },
  props: {

  },
  data () {
    return {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      year: '',
      time: 0,
    }
  },
  created() {
    // document.exitFullscreen();
    let timeStamp = new Date()
    this.year = new Date(timeStamp).getFullYear();
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
    });
  },
  methods: {
    closePage(){
      shutdownNow(this.headers, {key: 'bjjky'}).then((res) => {
        ElMessage({
            type: 'info',
            message: '已退出',
          })
      })
    },
    // 跳转功能模块
    goBak(){
      var selfUrl = this.$route.path
      if (selfUrl == '/dashBoard') {
        this.time = this.time + 1
        if (this.time == 2) {
          ElMessageBox.confirm(
            '此操作将在1分钟后关闭计算机，是否继续？',
            {
              confirmButtonText: '是',
              cancelButtonText: '否',
              type: 'warning',
            }
          )
          .then(() => {
            this.time = 0
            this.closePage()
          })
          .catch(() => {
            this.time = 0
            ElMessage({
              type: 'info',
              message: '已取消',
            })
          })
        }
      }else{
        this.time = 0
      }
      var url = sessionStorage.getItem('goBakUrl')
        sessionStorage.setItem('goBakUrl', '')
        sessionStorage.setItem('i', '')
        sessionStorage.setItem('page', '')
      if (url == '/timeLine') {
        this.$router.push({
          path: '/timeLineView'
        }) 
      }else{
        sessionStorage.setItem('pid', '')
      }
      if (url) {
        window.location.href = url
      }else{
        this.$router.push({
          path: 'dashBoard'
        }) 
      }
    }
  }
}
</script>
<style>
/* #logo{
  position: absolute;
  top: 8px; 
  left: 15px;
}
#logo img{
  width: auto; 
  height: 6vh;
  object-fit: scale-down;
} */
</style>
