<template>
<!-- 信息发布模块 -->
<div class="box">
  <el-row :gutter="10" id= "content">
    <el-col :span="4">
     <el-card class="paper2" shadow="always">
        <center>
          <p class="time-now">{{NatureIndex.nowTime}}</p>
          <p class="time-title">今天是{{NatureIndex.day}}{{NatureIndex.week}} </p>
        </center>

        <div class="nature">
          <div class="nature-now-right">
            <p class="time-now">{{NatureIndex.temp}}°</p>
            <p class="time-title">体感温度 {{NatureIndex.feels_like}}°</p>
          </div>
          <div class="nature-now-left">
            <p class="time-title">{{NatureIndex.text}}&nbsp;{{NatureIndex.icon}}</p>
            <p class="time-title">{{NatureIndex.wind_dir}} {{NatureIndex.wind_class}}</p>
            <el-tag type="warning" effect="plain">湿度{{NatureIndex.rh}}RH%</el-tag>
          </div>
        </div>

        <div class="nature">
          <div v-for="(info, i) in NatureInfo" :key="i" class="nature-other"> 
            <center>
              <p>{{info.week}}</p>
                  {{info.icon}}
              <p class="time-title">{{info.high}} ~ {{info.low}}°</p>
            </center>
          </div>
        </div>
     </el-card>
    </el-col>
    <el-col :span="4" v-for="(info, i) in data" :key="i">
      <el-card class="paper" shadow="always">
        <template #header>
          <div class="card-header">
            <center><h1>{{info.name}}</h1></center>
          </div>
        </template>
        <div class="paper3" v-if="info.newList">
          <NewsContent :newsInfo="info.newList"  @bigImage="showBigImg" ref="newsContent" />
        </div>
      </el-card>
    </el-col>
    <el-col :span="4">
      <el-card class="paper" shadow="always">
        <center><h1>要闻导览</h1></center>
        <br>
        <div class="title" v-for="(info, i) in showData" :key="i" @click="selContent(info.special_id, info.id, info.skey)" >
          <span>{{info.title}}</span>
        </div>
        <div class="dsk-nav">
          <span class="dsk-pre" @click="last()"></span>
          <span class="dsk-next" @click="next()"></span>
        </div>
        <div class="pageSize">
          <b>
            第 <span>{{ page }}</span> 页 &nbsp; 共 <span>{{ pageCount }}</span> 页 &nbsp; <span>{{ pageSize }}</span> 条/页
          </b>
        </div>
      </el-card>
    </el-col>
    <el-image-viewer v-if="imgViewerVisible" @close="closeImgViewer" :url-list="[currentImgViewerUrl]" :hide-on-click-modal="true"></el-image-viewer>
  </el-row>
</div>

</template>
<script>
import { getEduContent, getNatureInfo, getEduContentTop } from '@/api/contents'
import NewsContent from '../components/home/information/NewsContent'
export default {
  components: {
    NewsContent
  },
  data () {
    return {
      headers: {
        'token': sessionStorage.getItem('token'),
        'authkey': this.$authkey,
        'Content-Type': 'application/json'
      },
      data: [
        [],
        [],
        [],
        []
      ],
      pageCount: 0,
      pageSize: 3,
      count: 1,
      page: 1, 
      key: 0,
      index:0,
      Inform: [],
      NatureIndex: {
        nowTime: '00:00',
        day: '01月01日',
        week: '星期一',
        temp: '0',
        feels_like: '0',
        rh: '0',
        text: '晴',
        icon: '☀️',
        wind_dir: '南风',
        wind_class: '1级'
      },
      NatureInfo: [],
      newList: [],
      showData:[],
      timer: null,
      imgViewerVisible: false,
      currentImgViewerUrl:''
    }
  },
  mounted () {
    window.onbeforeunload = () => {
      this.clearTimer()
    }
  },
  created () {
   this.getContent()
   this.getInform()
   this.getNatureInfo()
   this.timer = setInterval(this.getNatureInfo, 30000)
  },
  methods: {
    getNatureInfo(){
      getNatureInfo(this.headers,{}).then((res) => {
        this.NatureIndex = res.data.now
        this.NatureInfo = res.data.forecasts
      })
    },
    getInform(){
      // getInform(this.headers,{}).then((res) => {
      //   this.Inform = res.data
      // })
    },    
    getContent(){
      var pid = sessionStorage.getItem('pid')
      getEduContent(this.headers,{"pid": pid}).then((res) => {
        this.pageCount = res.pageCount
        this.count = res.count
        this.data = res.data
        this.newList = res.newList
        this.totalData()
        this.onePageData()
      })
    },
    last(){
      this.page--
      if (this.page <= 0) {
        this.page++ 
      }else{
        this.onePageData()
      }
    },
    next(){
      this.page++
      if (this.page > this.pageCount) {
        this.page--
      }else{
        this.onePageData()
      }
    },

    showBigImg(url){
        this.imgViewerVisible = true
        this.currentImgViewerUrl= url
    },

    closeImgViewer(){
      this.imgViewerVisible = false
      this.currentImgViewerUrl = ''
    },
      // 获取总页数
    totalData() {
      // 总页数 = 数组长度和每页多少条取余 ， 如果余数是0 ， 代表正好 ， 如果不是0，就在总页数上+1 ， 即可得出总页数
      this.pageCount = this.newList.length % this.pageSize == 0 ? parseInt(this.newList.length / this.pageSize) : parseInt(this.newList.length / this.pageSize + 1);
    },
    // 获取每页数据
    onePageData(){
      // 开始 - 第一页就是0，第二页就是5...10
       const start = (this.page - 1)*this.pageSize

      // 结束 - 当前页*5 - 截取到什么 第一页就是截取到5...10...15
      const end = this.page*this.pageSize
      // 截取到当页的需要渲染的数据
      this.showData = this.newList.slice(start,end);
    },
    selContent(specialId, id, i){
      var pid = sessionStorage.getItem('pid')
      getEduContentTop(this.headers,{'special_id':specialId, 'id':id, 'pid': pid}).then((res) => {
        this.data[i] = []
        setTimeout(() => {
          this.data[i] = res.data
          this.$refs.newsContent[i].setInfo()
        }, 0)
      })
    },
    // 清除定时器
    clearTimer () {
      clearInterval(this.timer)
      this.timer = null
    },
  },
  beforeDestroy() {
    if(this.timer) {
      clearInterval(this.timer); //关闭
    } 
  }
}
</script>
<style scoped>
  .box{
      background: linear-gradient(135deg,#cebe29 0,#9b1f50 33%,#2989d8 71%,#89b4ff 91%);
      width: 100%;
      height: 100%;
  }
  #content{
    height: 70vh;
    width: 99.7%;
    position: absolute;
    top: 9vh;
    left: 0.3%;
  }
  .paper{
    height: 84vh;
    cursor:pointer;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border: 0px;
    color: #eaf6f9;
  }
  .paper2{
    height: 84vh;
    cursor:pointer;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border: 0px;
    color: #eaf6f9;
  }
  .time-now{
    color: #fcfcfd;
    font-size: 2.5em;
  }
  .time-title{
    margin: 5px 0px;
    color: #ccc;
  }
  .paper1{
    padding: 0px 50px;
    height: 100%;
    cursor:pointer;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border: 0px;
  }
  .paper3 {
    height: 72vh;
    cursor:pointer;
    /* overflow: hidden; */
    overflow-y: scroll;
    border-radius: 10px;
    font-size: 13px;
    border: 0px;
    color: #eaf6f9;
  }
  .paper3::-webkit-scrollbar {
      width: 5px;
  }
  .paper3:hover ::-webkit-scrollbar-track-piece {
      /* 滚动条的背景颜色 */
      border-radius: 6px;
  }
  .paper3:hover::-webkit-scrollbar-thumb:vertical {
      border-radius: 6px;
      outline: 1px solid #fff;
      outline-offset: -2px;
  }
  .paper3 img{
    width:100%;
    height: auto;
  }
  .paper3 p{
    font-weight: 800;
    letter-spacing: 1px;
  }
  
  h1{
    font-size: 1.5em;
    color: azure;
  }
  .title{
    width: 100%;
    border-radius: 10px;
    margin: 10px 0px;
    padding: 4% 15px;
    background: rgba(0, 0, 0, 0.2);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .title span{
    font-size: 0.9em;
    font-weight: 800;
    cursor: pointer;
    /* white-space: nowrap; */
  }
  /deep/.content-box img{
    width: 80%;
    margin: 15px 10%;
    height: auto;
  }
  .nature{
    display: flex; /* 使用Flexbox布局 */
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    padding:15px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
    justify-content: center;
  }
  .nature-now-left{
    padding-left: 15px
  }
  .nature-now-right{
    padding-right: 15px
  }
  .nature-other{
    margin: 10px;
  }
  /deep/.el-image-viewer__actions {
    width: 40vw;
    height: 8vh;
  }
  /deep/.el-image-viewer__actions__inner {
    font-size: 7vh;
  }
</style>

