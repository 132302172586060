<template>
    <vue-auto-scroll style="white-space: normal;height: 100%;" :data="newsInfo" v-if="isGo == true" :steep="steep" scrollDirection="top" :isRoller="true" :rollerScrollDistance="15">
      <div v-for="(newsData, index)  in newsInfo" :key="index" style="white-space: normal;height: auto;">
        <br>
          <h2>{{newsData.title}}</h2>
          <br>
          <div class="content-box" v-html="handleContent(newsData.content)" @click="showBigImg($event)"></div>
          <br> 
        <br>
      </div>
    </vue-auto-scroll>
  </template>
  <script>
  import 'vue3-marquee/dist/style.css'
  export default {
    components: {
    },
    props: {
      newsInfo: {
        type: Array
      }
    },
    data () {
      return {
        scroll: true,
        imgViewerVisible: false,
        currentImgViewerUrl:'',
        steep:0,
        isGo: true
      }
    },
    created () {
      setTimeout(() => {
        this.steep = 0.2
        this.isGo = false
      }, 800);
      setTimeout(() => {
        this.isGo = true
      }, 801);
    },
    methods: {
      handleContent(con){
        let reg = /<img/g
        let newImg = con.replace(reg,'<img style="cursor:pointer;"')
        return newImg
      },
  
      showBigImg(e){
        if(e.target.nodeName === 'IMG'){
          this.$emit('bigImage', e.target.src)
        }
      },
    }
  }
  </script>
  <style scoped>
  .content-box{
    font-size: 18px;
    letter-spacing: 0.2em;
    line-height: 1.5em;
    text-align: justify;
  }
  
  
  /deep/font{
    color: #eaf6f9;
  }
  </style>
  
  