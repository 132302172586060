<template>
<!-- mqtt -->
<div></div>
</template>
<script>
import mqtt from "mqtt/dist/mqtt.min.js"
import { eventGo } from '@/utils/event'
export default {
  components: {

  },
  data () {
    return {
       client: {}
    }
  },
  methods: {
    //连接服务器
    connect(admin_id, sid, user_id, timeLine) {
      let options = {
        // username: "admin",
        // password: "edu-xr",
        cleanSession : true,
        keepAlive:60,
        clientId: 'mqttjs_time_line',
        connectTimeout: 4000
      }
      this.client = mqtt.connect('wss://emq.edu-xr.cn:8084/mqtt',options);
      // this.client = mqtt.connect('ws://emq.edu-xr.cn:8083/mqtt',options);
      this.client.on("connect", (e)=>{
        window.console.log("成功连接服务器:",e);
        //订阅三个名叫'top/#', 'three/#'和'#'的主题
        this.client.subscribe([timeLine + admin_id + '_' + sid], { qos: 1 }, (err)=> {
          if (!err) {
             window.console.log("订阅成功");
            //向主题叫“pubtop”发布一则内容为'hello,this is a nice day!'的消息
            this.publish("pubtop", 'hello,this is a nice day!')
          } else {
             window.console.log('消息订阅失败！')
          }
        });
      });
      //是否已经断开连接
      this.mqttError()
      //监听获取信息
      this.getMessage()
    },
    //发布消息@topic主题  @message发布内容
    publish(topic,message) {
      if (!this.client.connected) {
         window.console.log('客户端未连接')
        return
      }
      this.client.publish(topic,message,{qos: 1},(err) => {
        if(!err) {
           window.console.log('主题为'+topic+ "发布成功")
        }
      })
    },
    //监听接收消息
    getMessage() {
      this.client.on("message", (topic, res) => {
        if(res) {
           this.msg = JSON.parse(this.Uint8ArrayToString(res))
           if (this.msg.code == 200) {
            var data = this.msg.data
            if (data.event.length>0) {
              eventGo(data.event)
            }
            sessionStorage.setItem('pid',data.pid)
            sessionStorage.setItem('i',data.i)
            sessionStorage.setItem('page',data.page)
            setTimeout(() => {
                this.$router.push({
                    path: '/dashBoard'
                }) 
            }, 0)
            setTimeout(() => {
              let query = {}
              if (data.bank_url) {
                if (data.bank_url.slice(-1) === '?') {
                  query = { bank_url: data.bank_url+'token='+sessionStorage.getItem('token') }
                }else{
                  query = { bank_url: data.bank_url }
                }
              }
              this.$router.push({
                  path: data.path,
                  hash: data.step,
                  query: query
              }) 
            }, 0)
           }
        }
      });
    },
    //监听服务器是否连接失败
    mqttError() {
      this.client.on('error',(error) => {
        alert('连接失败：')
        window.console.log('连接失败：',error)
        this.client.end()
        //重新连接
        this.reconnect()
      })
    },
    //取消订阅
    unsubscribe() {
      this.client.unsubscribe(this.mtopic, (error)=> {
        window.console.log('主题为'+ this.mtopic+'取消订阅成功',error)
      })
    },
    //断开连接
    unconnect() {
      this.client.end()
      this.client = null
      window.console.log('服务器已断开连接！')
    },
    //监听服务器重新连接
    reconnect() {
      this.client.on('reconnect', (error) => {
          window.console.log('正在重连:', error)
      });
    },
    // 二进制数据转换
    Uint8ArrayToString(array){
        let out, i, c;
        let char2, char3;
        out = "";
        const len = array.length;
        i = 0;
        while (i < len) {
            c = array[i++];
            switch (c >> 4) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    out += String.fromCharCode(c);
                    break;
                case 12:
                case 13:
                    char2 = array[i++];
                    out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
                    break;
                case 14:
                    char2 = array[i++];
                    char3 = array[i++];
                    out += String.fromCharCode(
                        ((c & 0x0f) << 12) |
                            ((char2 & 0x3f) << 6) |
                            ((char3 & 0x3f) << 0)
                    );
                    break;
            }
        }
        return out;
    }
  }
}
</script>
