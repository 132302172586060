import axiosApi from '@/utils/request'

// 获取导航栏展示的频道
export function getList(headers = {}, params = {}) {
    return axiosApi('/visual/special/get-list','post',params,headers,'',2)
}

// 获取导航栏展示的频道
export function getTimeList(headers = {}, params = {}) {
  return axiosApi('/visual/special/get-time-list','post',params,headers,'',2)
}

// 获取单个栏目信息
export function getSpecial(headers = {}, params = {}) {
  return axiosApi('/visual/special/get-special','post',params,headers,'',2)
}

// 专题访问量计数
export function specialBrowsing(headers = {}, params = {}) {
  return axiosApi('/visual/special/special-browsing','post',params,headers,'',2)
}