import axiosApi from '@/utils/request'

// 获取桌面应用
export function getModulesList(headers = {}, params = {}) {
    return axiosApi('/visual/modules/index','post',params,headers,'',2)
}

// 获取路由对应的背景图
export function getModulesBg(headers = {}, params = {}) {
    return axiosApi('/visual/modules/get-modules-bg','post',params,headers,'',2)
}

// 获取单个模块信息
export function getModulesOne(headers = {}, params = {}) {
    return axiosApi('/visual/modules/get-modules-one','post',params,headers,'',1)
}