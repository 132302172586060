<template>
<!-- XR资源详情 -->
<div class="box" v-if="!files.vr" style="position: absolute;top: 50%;right: 45%;color: red;">
  <h2>请使用VR设备体验。</h2>
</div>
<div class="box" v-else>
  <!-- 平面视频 -->
  <video  v-if="files.vr.video_type == 1" controlsList="nodownload nofullscreen noremoteplayback" disablepictureinpicture style="width: 100%; height:100vh;  border: 0px;" controls="controls" class="vjs-tech" playsinline="playsinline" webkit-playsinline="true" x5-playsinline="true" x5-video-player-type="h5" x5-video-player-fullscreen="false" tabindex="-1" preload="auto" :src="files.vr.url"></video>
  <!-- VR视频 -->
  <iframe v-if="files.vr.video_type == 2" style="width: 100%; height:100vh;  border: 0px;" :src="getUrl(files.vr.url)" allowfullscreen></iframe>
</div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    files: {
      type: Object
    }
  },
  created () {

  },
  data () {
    return {

    }
  },
  methods: {
    getUrl(url) {
      return axios.defaults.baseURL+'/pano-video/play?token='+sessionStorage.getItem('token')+'&url='+url
    },
  }
}
</script>
<style scoped>
.box{
  overflow: hidden;
}
</style>

