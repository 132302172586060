
import { Splide, SplideSlide, Options } from '@splidejs/vue-splide';
import { defineComponent, onMounted, ref } from 'vue';
import '@splidejs/vue-splide/css';


export default defineComponent( {
  props: {
    newsInfo: {
      type: Array
    }
  },
  name: 'ThumbnailsExample',
  data () {
    return {
      list: this.newsInfo
    }
  },
  components: {
    Splide,
    SplideSlide,
  },

  setup() {
    const main   = ref<InstanceType<typeof Splide>>();
    const thumbs = ref<InstanceType<typeof Splide>>();
    const slides = {}

    const mainOptions: Options = {
      type      : 'loop',
      autoplay  : true,
      rewind    : true,
      arrows    : false,
      perPage   : 1,
      perMove   : 1,
      pagination: false,
    };

    const thumbsOptions: Options = {
      type        : 'slide',
      rewind      : true,
      gap         : '1rem',
      pagination  : false,
      fixedWidth  : 110,
      arrows      : false,
      fixedHeight : 70,
      cover       : true,
      focus       : 'center',
      isNavigation: true,
      updateOnMove: true,
    };

    onMounted( () => {
      const thumbsSplide = thumbs.value?.splide;

      if ( thumbsSplide ) {
        main.value?.sync( thumbsSplide );
      }
    } );

    return {
      slides,
      main,
      thumbs,
      mainOptions,
      thumbsOptions,
    }
  },
  methods: {
    getData(list=[]) {
      this.list = list
    },
  },
} );
