import axios from "axios";
import { ElMessage } from 'element-plus'

// axios.defaults.baseURL = '//127.0.0.1' //本地
// axios.defaults.baseURL = '//api.local.edu-xr.com' //本地服务器
// axios.defaults.baseURL = '//t.api.edu-xr.com' //测试
axios.defaults.baseURL = '//v2test.edu-xr.com' //演示
// axios.defaults.baseURL = 'https://api.cloud.edu-xr.com' //正式

// const localhostUrl = '//local.hy.edu-xr.com/'  //本地服务器
// const localhostUrl = '//localhost:8080/'  //本地
// const localhostUrl = '//t.edu-xr.com/'  //测试
const localhostUrl = '//hy.edu-xr.com/'  //演示
// const localhostUrl = '//cloud.edu-xr.com/'  //正式

export default function axiosApi(url: string, method: string, params: object, headers: any, responseType: any, type = 0) {
  return new Promise((resolve, reject) => {
    axios({
        method: method,
        url: axios.defaults.baseURL+url,
        headers: headers,
        responseType: responseType,
        data: params
      })
      .then((res: { data: {code: number, msg: string}; }) => {
        if (type === 1){
          resolve(res.data)
        }else{
          if (res.data.code == 200) {
            resolve(res.data)
          } else {
            if (res.data.code === 410005) {
              ElMessage.error(res.data.msg)
              sessionStorage.clear()
              document.location = localhostUrl
              return false;
            }else{
              ElMessage.error(res.data.msg)
            }
            //  document.location = localhostUrl
          }
        }
      })
      .catch((err: object) => {
        reject(err)
      });
  })
  
}