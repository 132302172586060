<template>
<!-- 数据可视化中心 -->
<div class="box" :style="note">
  <el-row :gutter="30" id= "content">
    <el-col :span="getSpan(i)" v-for="(info, i) in data" :key="i">
      <div class="paper" shadow="always"  v-if="info.type === 1 && info.isbank != 1 ">
        <News :newsInfo="info"  @bigImage="showBigImg"/>
      </div>
      <el-card class="paper1" shadow="always"   v-else-if="info.isbank == 1 ">
        <NewsBank :newsInfo="info" />
      </el-card>
      <el-card class="paper1" shadow="always"  v-else>
        <NewsImage v-if="info.type === 2" :newsInfo="info" />
        <NewsVideo v-if="info.type === 4" :newsInfo="info" />
      </el-card>
    </el-col>
  </el-row>
  <div class="dsk-nav">
    <span class="dsk-pre" @click="last()"></span>
    <span class="dsk-next" @click="next()"></span>
  </div>
  <div class="pageSize">
     <b>
       第 <span>{{ page }}</span> 页 &nbsp; 共 <span>{{ pageCount }}</span> 页 &nbsp; <span>{{ pageSize }}</span> 条/页
     </b>
  </div>
  <el-image-viewer v-if="imgViewerVisible" @close="closeImgViewer" :url-list="[currentImgViewerUrl]" :hide-on-click-modal="true"></el-image-viewer>
</div>
</template>
<script>
import { getSpecial } from '@/api/special'
import { getContent } from '@/api/contents'
import NewsImage from './images'
import News from './news'
import NewsVideo from './video'
import NewsBank from './bank'
export default {
  components: {
    NewsImage,
    NewsVideo,
    News,
    NewsBank
  },
  data () {
    return {
      headers: {
        'token': sessionStorage.getItem('token'),
        'authkey': this.$authkey,
        'Content-Type': 'application/json'
      },
      note: {
        backgroundImage: '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
      imgViewerVisible: false,
      currentImgViewerUrl:'',
      pageCount: 0,
      pageSize: 6,
      count: 1,
      page: 1, 
      data: [],
    }
  },
  created () {
    this.getSpecial()
  },
  methods: {

    showBigImg(url){
      this.imgViewerVisible = true
      this.currentImgViewerUrl= url
    },

    closeImgViewer(){
      this.imgViewerVisible = false
      this.currentImgViewerUrl = ''
    },
    // 获取主题信息
    getSpecial(){
      var id = sessionStorage.getItem('pid')
      getSpecial(this.headers,{'type':2, 'id': id}).then((res) => {
        this.note.backgroundImage =  'url(' + res.data.surface_plot + ')'
        if (res.data.span>0) {
          this.pageSize = res.data.span 
        }
        this.getContent()
      })
    },
    // 获取内容列表
    getContent(){
      var id = sessionStorage.getItem('pid')
      getContent(this.headers,{'special_type': 2, 'special_id': id, 'pageSize': this.pageSize, 'page': this.page}).then((res) => {
        this.pageCount = res.pageCount
        this.count = res.count
        this.data = res.data
      })
    },
    // 获取比例
    getSpan(i){
      var span = 24/this.data.length
      if (this.isInteger(span) === true) {
        return span 
      }else{
        var number = Math.ceil(span)
        if (i == 0) {
          return number -1
        }else{
          return number
        }
      }
    },
    isInteger(obj) {
      return obj%1 === 0
    },
    last(){
      this.page--
      if (this.page <= 0) {
        this.page++ 
      }
      this.getContent()
    },
    next(){
      this.page++
      if (this.page > this.pageCount) {
        this.page--
      }
      this.getContent()
    }
  }
}
</script>
<style scoped>
  .box{
      background: linear-gradient(135deg,#cebe29 0,#9b1f50 33%,#2989d8 71%,#89b4ff 91%);
      width: 100%;
      height: 100%;
  }
  #content{
    width: 99%;
    height: 82%;
    position: absolute;
    top: 10%;
    left: 1%;
  }
  .paper1{
    height: 83vh;
    cursor:pointer;
    overflow-y: scroll;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border: 0px;
    color: #eaf6f9;
  }
  .paper{
    /* margin-bottom: 10%; */
    height: 83vh;
    cursor:pointer;
    /* overflow-y: scroll; */
    padding: 15px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border: 0px;
    color: #eaf6f9;
  }
  .content-box{
    font-size: 18px;
    letter-spacing: 0.2em;
    line-height: 1.5em;
    text-align: justify;
  }
  /deep/.content-box img{
    width: 80%;
    margin: 15px 10%;
    height: auto;
  }
  /deep/.el-image-viewer__actions {
    width: 40vw;
    height: 8vh;
  }
  /deep/.el-image-viewer__actions__inner {
    font-size: 7vh;
  }
</style>

