<template>
<!-- 板报 -->
<div class="box">
  <center><br><br><h1>VR 直 录 播</h1></center>
  <el-row :gutter="30" id= "nwes">
    <el-col :span="6" v-for="(info, i) in list" :key="i">
      <el-card class="paper" shadow="always" @click="goContent(info.id)">
        <img v-if="info.thumbnail" class="paperImg" :src="info.thumbnail" alt="">
        <img v-else class="paperImg" src="@/assets/theme/VR直录播.jpg" alt="">
        <center>{{info.name}}</center>
      </el-card>
    </el-col>
  </el-row>
  <div class="dsk-nav">
    <span class="dsk-pre" @click="last()"></span>
    <span class="dsk-next" @click="next()"></span>
  </div>
  <div class="pageSize">
    <b>
      第 <span>{{ page }}</span> 页 &nbsp; 共 <span>{{ pageCount }}</span> 页 &nbsp; <span>10</span> 条/页
    </b>
  </div>
</div>

</template>
<script>
import { getList } from '@/api/special'
export default {
  components: {

  },
  data () {
    return {
      headers: {
        'token': sessionStorage.getItem('token'),
        'authkey': this.$authkey,
        'Content-Type': 'application/json'
      },
      list: [
        {id:'123',name:'直播',thumbnail:''},
        {id:'456',name:'录播',thumbnail:''}
      ],
      pageSize:8,
      page: 1,
      pageCount: 0
    }
  },
  created () {
    this.getSpecialList()
  },
  methods: {
    // 获取专题列表
    getSpecialList(){
      getList(this.headers,{'type':1, 'pageSize':this.pageSize, 'page':this.page}).then((res) => {
        this.pageCount = res.pageCount
        this.list = res.data
      })
    },
    // 到达内容页
    goContent(pid){

    },
    last(){
      this.page--
      if (this.page <= 0) {
        this.page++ 
      }
      this.getSpecialList(this.activeName)
    },
    next(){
      this.page++
      if (this.page > this.pageCount) {
        this.page--
      }
      this.getSpecialList(this.activeName)
    }
  }
}
</script>
<style scoped>
  .box{
      background: linear-gradient(135deg,#cebe29 0,#9b1f50 33%,#2989d8 71%,#89b4ff 91%);
      width: 100%;
      height: 100%;
  }
  .box h1{
    color:#fcfcfd;
  }
  #nwes{
    width: 60%;
    position: absolute;
    top: 22%;
    right: 20%;
  }
  .paper{
    margin-bottom: 10%;
    cursor:pointer;
    color: #fcfcfd;
    border-radius: 10px;
    background: #f2f2f224;
    border: 0px;
  }
  .paperImg{
    border-radius: 10px;
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 10%);
    width: 100%;
    margin-bottom: 10px;
    height: 20vh;
    object-fit:cover;
  }
</style>

