import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import MqttView from '../views/MqttView.vue' // mqtt
import DashBoradView from '../views/DashBoradView.vue' // 桌面
import timeLineView from '../views/TimeLine.vue' // 时间轴模块页
import TimeLine from '../components/home/TimeLine.vue' // 时间轴最终页
import InformationBarView from '../views/InformationBarView.vue' // 要闻最终页
import Information from '../views/Information.vue' // 要闻导航页
import NewsPaperView from '../views/NewsPaperView.vue' // 板报
import NewsPaperContent from '../components/newspaper/content.vue' // 板报
import DataVisua from '../views/DataVisua.vue' // 数据可视化中心
import Tutor from '../views/TutorView.vue' // 导览
import Xrres from '../views/XrresView.vue' // XR资源
import XrresPaper from '../views/XrresPaperView.vue' // XR资源专题选择页
import XrresOne from '../components/xrres/index.vue' // XR资源详情
import GoBank from '../components/goBank/index.vue' //引用外部页面
import DigitalPavilion from '../views/DigitalPavilion.vue' // 云上数字展馆
import Live from '../views/LiveView.vue' // 直录播
import PhotoWall from '../views/PhotoWallView.vue' // 照片墙
import PhotoWallView from '../components/photo/index.vue'  // 照片墙详情页
import LeaderSpeech from '../views/LeaderSpeechView.vue' // 领导致辞
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashBoard'
  },
  {//桌面
    path: '/dashBoard',
    name: 'dashborad',
    component: DashBoradView
  },
  {//时间轴导航页
    path: '/timeLineView',
    name: 'timeLineView',
    component: timeLineView
  },
  {//时间轴最终页
    path: '/timeLine',
    name: 'timeLine',
    component: TimeLine
  },
  {//要闻导航页
    path: '/information',
    name: 'information',
    component: Information
  },
  {//要闻最终页
    path: '/informationBarView',
    name: 'informationBarView',
    component: InformationBarView
  },
  {//板报
    path: '/newsPaperView',
    name: 'newsPaperView',
    component: NewsPaperView
  },
  {// 板报内容
    path: '/newsPaperContent',
    name: 'newsPaperContent',
    component: NewsPaperContent,
  },
  {//数据可视化中心
    path: '/dataVisua',
    name: 'dataVisua',
    component: DataVisua
  },
  {//导览
    path: '/tutor',
    name: 'tutor',
    component: Tutor
  },
  {//XR资源
    path: '/xrres',
    name: 'xrres',
    component: Xrres
  },
  {//XR资源专题选择页
    path:'/xrresPaper',
    name: 'xrresPaper',
    component: XrresPaper
  },
  {//引用外部页面
    path: '/goBank',
    name: 'GoBank',
    component: GoBank
  },
  {//单个资源
    path: '/xrres-one',
    name: 'xrresOne',
    component: XrresOne
  },
  {//云上数字展馆
    path: '/digitalPavilion',
    name: 'digitalPavilion',
    component: DigitalPavilion
  },
  {//直录播
    path: '/live',
    name: 'live',
    component: Live
  },
  {//照片墙
    path: '/photoWall',
    name: 'photoWall',
    component: PhotoWall
  },
  {//照片墙详情页
    path: '/photoWallView',
    name: 'PhotoWallView',
    component: PhotoWallView
  },
  {//领导致辞
    path: '/leaderSpeech',
    name: 'leaderSpeech',
    component: LeaderSpeech
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
