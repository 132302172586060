import axios from "axios";
export default function axiosApi(url: string, method: string, params: object, headers:any, responseType: any) {
  return new Promise((resolve, reject) => {
    axios({
        method: method,
        url: 'http://localhost:886' + url,
        headers: headers,
        responseType: responseType,
        data: params
      })
      .then((res: { data: {code: number, msg: string}; }) => {
          resolve(res.data)
      })
      .catch((err: object) => {
        reject(err)
      });
  })
  
}