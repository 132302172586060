<template>
    <vue-auto-scroll v-if="isGo == true" style="height: 100%;" :steep="steep" :data="[newsInfo]"  scrollDirection="top" :isRoller="true" :rollerScrollDistance="15">
        <div style="white-space: normal; height: auto;">
          <br>
          <br> 
          <br>
            <h2>{{newsInfo.title}}</h2>
            <br>
            <div class="content-box" v-html="handleContent(newsInfo.content)" @click="showBigImg($event)"></div>
          <br> 
          <br>
        </div>
    </vue-auto-scroll>
  </template>
  <script>
  export default {
    components: {
    },
    props: {
      newsInfo: {
        type: Array
      }
    },
    data () {
      return {
        scroll: true,
        imgViewerVisible: false,
        currentImgViewerUrl:'',
        steep:0,
        isGo: true
      }
    },
    created () {
      setTimeout(() => {
       this.steep = 0.2
       this.isGo = false
      }, 800);
      setTimeout(() => {
        this.isGo = true
      }, 801);
    },
    methods: {
      handleContent(con){
        let reg = /<img/g
        let newImg = con.replace(reg,'<img style="cursor:pointer;"')
        return newImg
      },
  
      showBigImg(e){
        if(e.target.nodeName === 'IMG'){
          this.$emit('bigImage', e.target.src)
        }
      },
    }
  }
  </script>
  <style scoped>
  .content-box{
    font-size: 18px;
    letter-spacing: 0.2em;
    line-height: 1.5em;
    text-align: justify;
  }
  
  
  /deep/font{
    color: #eaf6f9;
  }
  </style>
  
  