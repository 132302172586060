<template>
    <!-- 新闻与公告 -->
    <div class="box">
      <center><br><br><h1> {{ModulesName}}</h1></center>
      <el-row :gutter="30" id= "nwes">
        <el-col :span="6" v-for="(info, i) in list" :key="i">
          <el-card class="paper" shadow="always" @click="goContent(info.id)">
            <img v-if="info.thumbnail" class="paperImg" :src="info.thumbnail" alt="">
            <img v-else class="paperImg" src="@/assets/theme/教育要闻.jpg" alt="">
            <center>{{info.name}}</center>
          </el-card>
        </el-col>
      </el-row>
      <div class="dsk-nav">
        <span class="dsk-pre" @click="last()"></span>
        <span class="dsk-next" @click="next()"></span>
      </div>
      <div class="pageSize">
        <b>
          第 <span>{{ page }}</span> 页 &nbsp; 共 <span>{{ pageCount }}</span> 页 &nbsp; <span>10</span> 条/页
        </b>
      </div>
    </div>
    
    </template>
    <script>
    import { getList, specialBrowsing } from '@/api/special'
    export default {
      components: {
    
      },
      data () {
        return {
          headers: {
            'token': sessionStorage.getItem('token'),
            'authkey': this.$authkey,
            'Content-Type': 'application/json'
          },
          list: [],
          ModulesName: '',
          pageSize:8,
          page: 1,
          pageCount: 0,
        }
      },
      created () {
        this.getSpecialList()
      },
      methods: {
        // 获取专题列表
        getSpecialList(){
          sessionStorage.setItem('goBakUrl', '')
          getList(this.headers,{'type':3, 'pageSize':this.pageSize, 'page':this.page}).then((res) => {
            this.list = res.data
            if (this.list.length == 1) {
              specialBrowsing(this.headers, {'type': 3, 'special_id': this.list[0].id})
              sessionStorage.setItem('pid', this.list[0].id)
              this.$router.push({
                name: 'informationBarView'
              })
            }else{
                this.ModulesName = res.ModulesName
                this.pageCount = res.pageCount
            }
          })
        },
        // 到达内容页
        goContent(pid){
          specialBrowsing(this.headers, {'type': 3, 'special_id': pid})
          sessionStorage.setItem('goBakUrl', '/information')
          sessionStorage.setItem('pid', pid)
          setTimeout(() => {
            this.$router.push({
              name: 'informationBarView'
            })
          }, 300);
        },
        last(){
          this.page--
          if (this.page <= 0) {
            this.page++ 
          }
          this.getSpecialList(this.activeName)
        },
        next(){
          this.page++
          if (this.page > this.pageCount) {
            this.page--
          }
          this.getSpecialList(this.activeName)
        }
      }
    }
    </script>
    <style scoped>
      .box{
          background: linear-gradient(135deg,#cebe29 0,#9b1f50 33%,#2989d8 71%,#89b4ff 91%);
          width: 100%;
          height: 100%;
      }
      .box h1{
        color:#fcfcfd;
      }
      #nwes{
        width: 60%;
        position: absolute;
        top: 22%;
        right: 20%;
      }
      .paper{
        margin-bottom: 10%;
        cursor:pointer;
        color: #fcfcfd;
        border-radius: 10px;
        background: #f2f2f224;
        border: 0px;
      }
      .paperImg{
        border-radius: 10px;
        box-shadow: 0 2px 16px 0 rgb(0 0 0 / 10%);
        width: 100%;
        margin-bottom: 10px;
        height: 20vh;
        object-fit:cover;
      }
    </style>
    
    