<template>
  <div class="dsk-content">
    <div style="height:auto;" @click="onDialog()" >
      <img src="@/assets/image/icon_operation_playtime.png" style="position: absolute;top: 5em;left: 40%; width:25%;">
      <img class="app" src="@/assets/theme/时间轴.jpg"/>
    </div>
    <h2>{{ list.title }}</h2>
  <el-dialog
        v-model="dialogVisible"
        :before-close="closeDialog"
        :destroy-on-close="true"
        width="100%"
        height="60vh"
        top="0px"
        :isDisabled="false">
    <iframe style="height:50vh;" id="bankUrl" :src="list.bank_url" allowfullscreen></iframe>
  </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    newsInfo: {
      type: Array
    }
  },
  components: {

  },
  data () {
    return {
      dialogVisible: false,
      list: this.newsInfo
    }
  },
  methods: {
    closeDialog() {
      this.dialogVisible = false
    },
    onDialog() {
      this.dialogVisible = true
    }
  }
};
</script>
<style scoped>
.app{
  border-radius: 10px;
  box-shadow: 0 2px 16px 0 rgb(0 0 0 / 10%);
  width: 100%;
  margin-bottom: 10px;
  height: 35vh;
  object-fit:cover;
}
#bankUrl {
  width: 100%; height: 60%; border: 0px;
}
/deep/ .el-dialog__header {
    margin-right: 0px;
    background-color: #222;
}
/deep/ .el-dialog__body {
    background-color: #222;
}
</style>