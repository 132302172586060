<template>
<!-- 数字时间轴 -->
<div class="box">
  <el-row :gutter="30" id= "content">
    <el-col :span="getSpan(i)" v-for="(info, i) in data" :key="i">
      <el-card class="paper" shadow="always">
          <div  v-if="info.isbank == 1 ">
            <NewsBank :newsInfo="info" />
          </div>
          <div v-else>
            <vue3-seamless-scroll
            hover-stop="true"
            :list="listData"
            hover="true"
            step="0.2">
            <h2> {{info.title}} </h2>
            <br>
            <div class="content-box" v-html="info.content"></div>
            </vue3-seamless-scroll>
            <NewsImage v-if="info.type === 2" :newsInfo="info" />
            <NewsVideo v-if="info.type === 4" :newsInfo="info" />
          </div>
      </el-card>
    </el-col>
  </el-row>
</div>
</template>
<script>
import { getSpecial } from '@/api/special'
import { getContent } from '@/api/contents'
import NewsImage from './news/images'
import NewsVideo from './news/video'
import NewsBank from './news/bank'
export default {
  components: {
    NewsImage,
    NewsVideo,
    NewsBank
  },
  data () {
    return {
      headers: {
        'token': sessionStorage.getItem('token'),
        'authkey': this.$authkey,
        'Content-Type': 'application/json'
      },
      pageCount: 0,
      pageSize: 6,
      count: 1,
      specialId: '',
      page: 1, 
      data: [],
      listData: [{
        'title': '无缝滚动第一行无缝滚动第一行',
      }, {
        'title': '无缝滚动第二行无缝滚动第二行',
      },{
        'title': '无缝滚动第一行无缝滚动第一行',
      }, {
        'title': '无缝滚动第二行无缝滚动第二行',
      },{
        'title': '无缝滚动第一行无缝滚动第一行',
      }, {
        'title': '无缝滚动第二行无缝滚动第二行',
      },{
        'title': '无缝滚动第一行无缝滚动第一行',
      }, {
        'title': '无缝滚动第二行无缝滚动第二行',
      },{
        'title': '无缝滚动第一行无缝滚动第一行',
      }, {
        'title': '无缝滚动第二行无缝滚动第二行',
      },{
        'title': '无缝滚动第一行无缝滚动第一行',
      }, {
        'title': '无缝滚动第二行无缝滚动第二行',
      },{
        'title': '无缝滚动第一行无缝滚动第一行',
      }, {
        'title': '无缝滚动第二行无缝滚动第二行',
      }],
    }
  },
  created () {
    // this.getContent()
  },
  methods: {
    getData(pid) {
      this.specialId = pid
      this.getContent()
    },
    // 获取内容列表
    getContent(){
      getContent(this.headers,{'special_type': 1, 'special_id': this.specialId, 'pageSize': this.pageSize, 'page': this.page}).then((res) => {
        this.pageCount = res.pageCount
        this.count = res.count
        this.data = []
        setTimeout(() => {
          this.data = res.data
        }, 0)
      })
    },
    // 获取比例
    getSpan(i){
      return 4 
    },
    isInteger(obj) {
      return obj%1 === 0
    },
    last(){
      this.page--
      if (this.page <= 0) {
        this.page++ 
      }
      this.getContent()
    },
    next(){
      this.page++
      if (this.page > this.pageCount) {
        this.page--
      }
      this.getContent()
    }
  }
}
</script>
<style scoped>
  #content{
    width: 100%;
    height: 100%;
  }
  .paper{
    /* margin-bottom: 10%; */
    height: 68vh;
    cursor:pointer;
    overflow-y: scroll;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    border: 0px;
    color: #eaf6f9;
  }
  .paper::-webkit-scrollbar {
      width: 8px;
  }
  .paper:hover ::-webkit-scrollbar-track-piece {
      /* 滚动条的背景颜色 */
      border-radius: 6px;
  }
  .paper:hover::-webkit-scrollbar-thumb:vertical {
      border-radius: 6px;
      outline: 2px solid #fff;
      outline-offset: -2px;
  }
  /deep/.content-box img{
    width: 80%;
    margin: 15px 10%;
    height: auto;
  }
  .dsknav{
    position: absolute;
    bottom: -20px;
  }
  .sizepage{
    position: absolute;
    bottom: -20px;
  }
  /deep/font{
    color: #eaf6f9;
  }
  /deep/.content-box img{
    width: 80%;
    margin: 15px 10%;
    height: auto;
  }
</style>

