import { shutdownNow } from '@/api/goPower'
import { ElMessage } from 'element-plus'
let headers= {
    'Content-Type': 'multipart/form-data'
}
export function eventGo(event){
    if (event == 'shutdown') {
        shutdownNow(headers, {key: 'bjjky'}).then((res) => {
            ElMessage({
                type: 'info',
                message: '已退出',
            })
        })   
    }
}