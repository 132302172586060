<template>
<!-- XR资源详情 -->
<div id="viewer"></div>
</template>
<script>
import  { Viewer }  from 'photo-sphere-viewer'
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
export default {
  props: {
    files: {
      type: Object
    }
  },
  mounted () {
    this.previewVrImage()
  },
  data () {
    return {
      psv: null,
      img: this.files.vr.url
    }
  },
  methods: {
    // 预览vr图片
    previewVrImage () {
      this.psv = new Viewer({
          container: document.getElementById('viewer'),
          panorama: this.img,
          loadingTxt:'',
          size:{
              width: '100vm',
              height: '100vh',
          },
          navbar: false
      });
    }
  }
}
</script>
<style scoped>
</style>

