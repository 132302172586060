<template>
  <!-- 导览模块 -->
  <div class="box">
    <center><br><br><h1> {{ModulesName}} </h1></center>
    <el-row :gutter="30" id= "nwes">
      <el-col :span="6" v-for="(info, i) in list" :key="i">
        <el-card class="paper" shadow="always" @click="goContent(info)">
          <img v-if="info.thumbnail" class="paperImg" :src="info.thumbnail" alt="">
          <img v-else class="paperImg" src="@/assets/theme/数据可视化中心.jpg" alt="">
          <center>{{info.name}}</center>
        </el-card>
      </el-col>
    </el-row>
    <div class="dsk-nav">
      <span class="dsk-pre" @click="last()"></span>
      <span class="dsk-next" @click="next()"></span>
    </div>
    <div class="pageSize">
      <b>
        第 <span>{{ page }}</span> 页 &nbsp; 共 <span>{{ pageCount }}</span> 页 &nbsp; <span>10</span> 条/页
      </b>
    </div>
  </div>
  
  </template>
  <script>
  import { getModulesOne } from '@/api/modules'
  import { getList, specialBrowsing } from '@/api/special'
  import { ElMessage } from 'element-plus'
  export default {
    components: {
  
    },
    data () {
      return {
        headers: {
          'token': sessionStorage.getItem('token'),
          'authkey': this.$authkey,
          'Content-Type': 'application/json'
        },
        ModulesName: '',
        info: {
          bank_url: '',
          isbank: 1
        },
        list: [],
        pageSize:8,
        page: 1,
        pageCount: 0
      }
    },
    created () {
      sessionStorage.setItem('goBakUrl', '')
      this.getSpecialList()
    },
    methods: {
      // 获取专题列表
      getSpecialList(pid = 0){
        getList(this.headers,{'type':4, 'pageSize':this.pageSize, 'page':this.page, 'pid':pid}).then((res) => {
          this.list = res.data
          if (this.list.length <= 0) {
            this.getModulesOne()
          }else{
            this.pageCount = res.pageCount
            this.ModulesName = res.ModulesName
          }
        })
      },
      last(){
        this.page--
        if (this.page <= 0) {
          this.page++ 
        }
        this.getSpecialList(this.activeName)
      },
      next(){
        this.page++
        if (this.page > this.pageCount) {
          this.page--
        }
        this.getSpecialList(this.activeName)
      },
      getModulesOne(){
        specialBrowsing(this.headers, {'type': 4, 'special_id': 0})
        getModulesOne(this.headers, { 'path': '/dataVisua' }).then(res=>{
          let data = res.data
          if (data.bank_url == '' || data.isbank == 0 || data.isbank == '' || data.isbank == null || data.bank_url == null) {
            data.bank_url = 'https://weiyulong.yuschool.cn/fusion_engine/EtherWhale/whaleDetail?whale_page=EYXdI4&app_id=16912&token=JkyzyzZDVjNTU3NjU2MjUxY1FmYTBlMjMzMmI5MTE6MDA'
          }
          sessionStorage.setItem('goBakUrl', '/dashBoard')
          setTimeout(() => {
            this.$router.push({path: '/goBank', query: { bank_url: data.bank_url }})
          }, 300);
        })
      },
      goContent(info) {
        specialBrowsing(this.headers, {'type': 4, 'special_id': info.id})
       if (info.isbank == 1) {
          sessionStorage.setItem('goBakUrl', '/dataVisua')
          this.info.isbank = info.isbank
          setTimeout(() => {
            this.$router.push({path: '/goBank', query: { bank_url: info.bank_url+'token='+this.headers.token }})
          }, 300);
       }else{
        ElMessage({
          type: 'error',
          message: '未开启',
        })
       }
      }
    }
  }
  </script>
  <style scoped>
    .box{
      background: linear-gradient(135deg,#cebe29 0,#9b1f50 33%,#2989d8 71%,#89b4ff 91%);
      width: 100%;
      height: 100%;
      z-index: 19000;
    }
    .box h1{
      color:#fcfcfd;
    }
    #nwes{
      width: 60%;
      position: absolute;
      top: 22%;
      right: 20%;
    }
    .paper{
      margin-bottom: 10%;
      cursor:pointer;
      color: #fcfcfd;
      border-radius: 10px;
      background: #f2f2f224;
      border: 0px;
    }
    .paperImg{
      border-radius: 10px;
      box-shadow: 0 2px 16px 0 rgb(0 0 0 / 10%);
      width: 100%;
      margin-bottom: 10px;
      height: 20vh;
      object-fit:cover;
    }
  </style>
  
  