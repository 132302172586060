<template>
    <div id="dasky" v-show="isShow == true">
        <div class="step"  v-for="(info, i) in step" :key="i">
          <div class="dsk-circle" @click="getContent(info.id,i)">{{info.name}}</div>
          <h2 class="dsk-circle-title">{{info.description}}</h2>
        </div>
    </div>

    <div id="contentBox" v-if="isFirst == 0">
        <NewsContent v-if="list.length > 1" ref="NewsContent"/>
        <div v-if="list.length == 1">
            <NewsBank v-if="list[0].isbank == 1 " ref="NewsContent" />
            <NewsImg v-else-if="list[0].type == 2 " ref="NewsContent" />
            <NewsVideo v-else-if="list[0].type == 4 " ref="NewsContent" />
        </div>
    </div>

    <div id="contentBox" v-else >
        <Overview ref="NewsContent"/>
    </div>

    <div class="dsk-nav">
      <span class="dsk-pre" @click="last()" ></span>
      <span class="dsk-next" @click="next()"></span>
    </div>
    <div class="pageSize">
     <b> 第 <span>{{ page }}</span> 页 &nbsp; 共 <span>{{ pageCount }}</span> 页 &nbsp; <span>10</span> 条/页</b>
    </div>
</template>
<script>
import { getList, getTimeList } from '@/api/special'
import { getContentList } from '@/api/contents'
import NewsImg from './NewsImg'
import NewsContent from './NewsContent'
import NewsVideo from './NewsVideo'
import NewsBank from './NewsBank'
import Overview from './Overview'

import '../../../public/css/dasky.min.css'
export default {
  components: {
    NewsImg,
    NewsContent,
    NewsVideo,
    NewsBank,
    Overview
  },
  props: {

  },
  data () {
    return {
      isShow: false,
      activeName:'',
      headers: {
        'token': sessionStorage.getItem('token'),
        'authkey': this.$authkey,
        'Content-Type': 'application/json'
      },
      step: [],
      page:1,
      pageCount:0,
      count:0,
      stepN: 0,
      i: 0,
      list: [],
      overview: [],
      isFirst: 0
    }
  },
  created () {
    if (this.$route.hash) {
      this.isFirst = 0
    }else{
      this.isFirst = 1
    }
   var page = sessionStorage.getItem('page')
   if (page) {
      this.page = page
   }
   this.getSpecialSon()
  },
  methods: {

    // 获取子集节点
    getActiveName(){
      setTimeout(() => {
        this.getSpecialSon()
      }, 0)
    },

    // 获取子集节点
    getSpecialSon(){
      sessionStorage.setItem('goBakUrl', '/timeLineView')
      var pid = sessionStorage.getItem('pid')
      this.i = sessionStorage.getItem('i')
      this.step = []
      // setTimeout(() => {
      //       window.getDask()
      // }, 0)
      this.isShow = false
      getTimeList(this.headers,{ "pid": pid, "page": this.page, "is_first": this.isFirst }).then((res) => {
          this.count = res.count
          this.pageCount = res.pageCount
          this.step = res.data
          if (this.step.length>0) {
              if (this.isFirst == 1) {
                this.$refs.NewsContent.getData(res.newdata)  
              }else{
                if (this.step[this.i]) {
                    this.stepN = this.step[this.i].id
                    this.getContent(this.step[this.i].id,this.i)
                }else{
                    this.stepN = this.step[0].id
                    this.getContent(this.step[0].id,0)
                }
              }
          }
          setTimeout(() => {
            window.getDask()
            this.isShow = true
          }, 0)
      })
    },

    // 获取内容
    getContent (id,i) {
      getContentList(this.headers,{"special_id":id}).then((res) => {
        if (res.data.length > 1) {
          sessionStorage.setItem('goBakUrl', '/timeLine')
          sessionStorage.setItem('i', i)
          this.isFirst = 0
          this.stepN = id
          this.list = res.data
          setTimeout(() => {
            if (res.data.length > 1 ) {
              this.$refs.NewsContent.getData(id)  
            }else if (res.data.length == 1 ) {
              this.$refs.NewsContent.getData(this.list[0])  
            }
          },0) 
        }
      })
    },

    last(){
      this.page--
      if (this.page <= 0) {
        this.page++ 
      }
      this.getSpecialSon()
    },
    next(){
      this.page++
      if (this.page > this.pageCount) {
        this.page--
      }
      this.getSpecialSon()
    }
  }
}
</script>

<style>
  #contentBox{
    position: absolute;
    bottom: 175px;
    width: 100%;
    margin-left: 0.6%;
    /* left: 1%; */
    padding-bottom: 1.5vh;
    /* height: 70vh; */
  }
  ul{
    list-style-type:none
  }
  li{
      margin: 10px;
  }
</style>
