import axiosApi from '@/utils/request'

// 获取指定格式内容
export function getContentList(headers = {}, params = {}) {
    return axiosApi('/visual/content/index','post',params,headers,'',2)
}

// 获取内容（带分页）
export function getContent(headers = {}, params = {}) {
  return axiosApi('/visual/content/get-content','post',params,headers,'',2)
}

// 获取标语
export function getInform(headers = {}, params = {}) {
  return axiosApi('/visual/content/get-inform','post',params,headers,'',2)
}

// 获取天气信息
export function getNatureInfo(headers = {}, params = {}) {
  return axiosApi('/visual/content/get-nature-info','post',params,headers,'',2)
}

// 获取教育要闻信息
export function getEduContent(headers = {}, params = {}) {
  return axiosApi('/visual/content/get-edu-content','post',params,headers,'',2)
}

// 获取教育要闻某一栏专题信息并置顶某一条
export function getEduContentTop(headers = {}, params = {}) {
  return axiosApi('/visual/content/get-edu-content-top','post',params,headers,'',2)
}
